$switch-width: 56px;

.switch {
	margin-top: (strip-unit($input-height) * $font-size-root - $switch-width/2) / 2;
}
.switch-toggle {
	position: absolute;
	margin-left: -9999px;
	visibility: hidden;
	& + label {
		display: block;
		position: relative;
		cursor: pointer;
		outline: none;
		user-select: none;
	}
}
// DEFAULT
input.switch-toggle-round + label {
	padding: 2px;
	width: $switch-width;
	height: $switch-width/2;
	background-color: $border-color;
	border-radius: $switch-width/2;
	&:before, &:after {
		display: block;
		position: absolute;
		top: 1px;
		left: 1px;
		bottom: 1px;
		content: "";
	}
	&:before {
		right: 1px;
		background-color: #f1f1f1;
		border-radius: $switch-width/2;
		transition: background 0.4s;
	}
	&:after {
		width: $switch-width / 2 - 2; 
		background-color: #fff;
		border-radius: 100%;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
		transition: margin 0.4s;
	}
}
input.switch-toggle-round:checked + label:before {
  background-color: $brand-success;
}
input.switch-toggle-round:checked + label:after {
  margin-left: $switch-width / 2;
}
// FLAT
input.switch-toggle-round-flat + label {
	padding: 2px;
	width: $switch-width;
	height: $switch-width/2;
	background-color: $border-color;
	border-radius: $switch-width/2;
	transition: background 0.4s;
	&:before, &:after {
		display: block;
		position: absolute;
		content: "";
	}
	&:before {
		top: 2px;
		left: 2px;
		bottom: 2px;
		right: 2px;
		background-color: #fff;
		border-radius: $switch-width/2;
		transition: background 0.4s;
	}

	&:after {
		top: 4px;
		left: 4px;
		bottom: 4px;
		width: $switch-width/2 - 8;
		border-radius: $switch-width/2 - 8;
		background-color: $border-color;
		transition: margin 0.4s, background 0.4s;
	}
}
input.switch-toggle-round-flat:checked + label {
	background-color: $brand-success;
}
input.switch-toggle-round-flat:checked + label:after {
	margin-left: $switch-width/2;
	background-color: $brand-success;
}