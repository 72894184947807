.nav-stats {
	display:block;
	line-height:1;
	vertical-align:middle;
	height:36px;
	margin-top: ($navbar-height - 36 ) / 2;
	font-weight: bold;
	text-align:center;
	border-radius: 3px;
	padding:4px 12px;
	small {
		display:block;	
		font-size:.625rem;
		margin-top:2px;
	}
}