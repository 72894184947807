.sidebar-email {
	line-height: normal;
	padding-left: 0;
	margin: 0;
	list-style: none;

	.sidebar-email-item {
		.btn {
			margin: .625rem;
		}
		a.nav-link {
			position:relative;
			padding: .625rem .625rem  .625rem  2rem;  
			display: block;
			.sidebar-email-subject {
				display: block;
				font-size: .9rem;
			}
			.sidebar-email-message {
				display: block;
				font-size: .8rem; 
				margin-top: 2px;  
			}
		} 
		&.new {
			a:after {
				position: absolute;
				left:12px; 
				top:.625rem;
				width:10px;
				height:10px;
				margin-top:3px; 
				display: inline-block;
				content: '';
				border-radius: 50%;
			}
		}
	}
}