.o-visible {
	overflow: visible;
}
.p-absolute {
	position:absolute;
}
.height-180 {
	height: 180px;
}
.height-350 {
	height: 350px;
}
.fs {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.m-b-05 {
	margin-bottom: .625rem;
}

.icon-block {
	display: inline-block;
	width: 80px; 
	height:80px; 
	line-height: 80px; 
	text-align: center; 
	background: #f3f3f3; 
}
