.sidebar-stats {
	display: block;
	position: relative;
	text-align:center;
	margin: .625rem;
	border-radius: 5px;
	padding: .625rem;
	line-height: 1; 
	color: $text-muted; 
	background: #fff;
	
	.sidebar-stats-lead {
		font-size: 2.3rem; 
		height: 40px;
		line-height: 40px; 
		text-align: center; 
		font-weight: 500; 
		span {
			display: inline-block;
			vertical-align: middle;
			line-height: 0;
		} 
		small {
			font-size: 40%; 
			font-weight: bold;
			vertical-align: middle; 
			i {
				font-size: 1rem;
			}
		}
	}
} 
