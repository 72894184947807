#chat {
	padding-bottom: 60px;
	.reply {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		.form-control {
			border-left: none;
			border-right: none;
			border-bottom: none;
			border-radius: none;
		}
	}
	.media-list {
		margin-bottom: 0;
		.media {
			margin:0;
		}
	}
}