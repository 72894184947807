.forum-thread {
	.media  {
		overflow: visible;
	}
	.forum-icon-wrapper	{
		position: relative;
	}
	a.forum-thread-icon {
		width: 50px;
		height: 50px;
		line-height:50px;
		text-align: center;
		color: $text-muted-light;
		border:1px solid $border-color;
		border-radius:50%;
		display:block;
	}
	a.forum-thread-user {
		position: absolute;
		right:0;
		bottom:-5px;
		img {
			border:1px solid #fff;
			box-shadow: 0 2px 3px rgba(0,0,0, .05);
		}
		&:hover img {
			opacity: .7;
		}
	}
}